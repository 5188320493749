/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import useBlockBackButton from "../../utils/preventBrowserBack";
import CtaButton from "../ctaButton";

const ShopPage = () => {
  useBlockBackButton();
  return (
    <div className="landing-content game-content shop-page">
      <video
        src="video/mobileEnd.mp4"
        playsInline
        autoPlay
        muted
        width="900px"
        height="1640px"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "scaleX(1.033)",
        }}
      />

      <div className="footer">
        <div className="shop-now-footer">
          <img
            src="images/shopNow.png"
            alt="breeze shop now"
            height={16}
            className="animate-cta shop-now-text"
          />
          <CtaButton
            href="https://bs.serving-sys.com/Serving/adServer.bs?cn=trd&ebaddid=[DEVICE_ID]&pli=1080347185&us_privacy=&adid=1093507319&ord=[ADCOLONY_TIMESTAMP_MILLIS]"
            imageUrl="images/Shopee.png"
            tagLabel="ENGAGE_DEC_TAP_SHOPEE_SHOP_NOW"
          />
          <img src="images/divider.png" alt="breeze divider" height={36} />
          <CtaButton
            href="https://bs.serving-sys.com/Serving/adServer.bs?cn=trd&ebaddid=[DEVICE_ID]&pli=1080347184&us_privacy=&adid=1093507321&ord=[ADCOLONY_TIMESTAMP_MILLIS]"
            imageUrl="images/lazada.png"
            tagLabel="ENGAGE_DEC_TAP_LAZADA_SHOP_NOW"
          />
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
